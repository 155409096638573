import React, { useEffect, useState } from "react";
import { osName, deviceType } from "react-device-detect";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import AuthContainer from "../../containers/AuthContainer";
import styles from "./Login.module.scss";
import Text from "../../components/UI/atoms/Text";
import Input from "../../components/UI/atoms/Input";
import { ReactComponent as EyeOpen } from "../../assets/images/eyeOpen.svg";
import { ReactComponent as EyeClosed } from "../../assets/images/eyeClosed.svg";
import Button from "../../components/UI/atoms/Button";
import { colors } from "../../sdk/colors";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { LoginBody } from "../../models/auth";
import { useLoginMutation } from "../../redux/features/auth/authApiSlice";
import { handleUserLogin } from "../../redux/features/auth/authSlice";
import { useToast } from "../../context/toast.context";
import { useAppDispatch } from "../../redux/hooks";
import { ErrorResponse } from "../../models/utilityTypes";
import { useInstallPrompt } from "../../context/installprompt.context";
import { handleSaveUser } from "../../redux/features/user/userSlice";
import { useEffectOnce } from "react-use";

export interface BeforeInstallPromptEvent extends Event {
  prompt: () => void;
  userChoice: Promise<{ outcome: "accepted" | "dismissed" }>;
}

function Login() {
  const { deferredPrompt, setDeferredPrompt } = useInstallPrompt();
  const [searchParams] = useSearchParams();

  const intent = searchParams.get("intent");
  const nextUrl = decodeURIComponent(searchParams.get("next") || "");

  useEffectOnce(() => {
    if (!!intent && intent === "mobile_payment") {
      show({ message: "Log in to continue with payment", variant: "info" });
    }
  });

  useEffect(() => {
    if (deferredPrompt) {
      const promptEvent = deferredPrompt as BeforeInstallPromptEvent;
      promptEvent.prompt();
      promptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null); // Clear the prompt after use
      });
    }
  }, [deferredPrompt, setDeferredPrompt]);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      const promptEvent = deferredPrompt as any;
      promptEvent.prompt();
      promptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null); // Clear the prompt after use
      });
    }
  };
  const { show } = useToast();
  const dispatch = useAppDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [login, { isLoading }] = useLoginMutation();
  const navigate = useNavigate();

  const initialValues: LoginBody = {
    phoneNumber: "",
    password: "",
  };

  const validationSchema = Yup.object({
    phoneNumber: Yup.string()
      .matches(
        /^(\+234|0)(701|702|703|704|705|706|707|708|709|802|803|804|805|806|807|808|809|810|811|812|813|814|815|816|817|818|819|909|908|901|902|903|904|905|906|907|910|911|912|913|914|915|916|917|918|919)([0-9]{7})$/,
        "Invalid phone number"
      )
      .required("This is a required field"),
    password: Yup.string().required("This is a required field"),
  });

  const onSubmit = async (values: LoginBody) => {
    const phone = values?.phoneNumber.match(/^([0]{1})[0-9]{10}$/)
      ? values?.phoneNumber.replace(/\D|^0+/g, "+234")
      : values.phoneNumber;
    const submitValues: LoginBody = {
      ...values,
      phoneNumber: phone,
      meta: {
        osname: osName,
        deviceId: "sdsd",
        ipaddress: "127.0.0",
      },
    };
    console.log(submitValues);
    try {
      const response = await login(submitValues).unwrap();
      console.log(response);
      dispatch(handleUserLogin(response));
      dispatch(
        handleSaveUser({
          name: `${response?.data?.firstName || ""}`,
          avatar: `${response?.data.avatar?.url || ""}`,
          id: response?.data?.id,
        })
      );
      handleInstallClick();
      if (intent && intent === "mobile_payment") {
        if (!!nextUrl) {
          window.location.href = nextUrl;
        } else {
          show({ message: "no payment url provided", variant: "info" });
          if (response.profiles.length > 0) {
            navigate("/app", { replace: true });
          } else {
            navigate("/app/guardian", { replace: true });
          }
        }
      } else {
        if (response.profiles.length > 0) {
          navigate("/app", { replace: true });
        } else {
          navigate("/app/guardian", { replace: true });
        }
      }
    } catch (e: any) {
      console.log(e);
      const error: ErrorResponse = e;
      show({ message: `${error.data.message}`, variant: "error" });
    }
  };

  return (
    <AuthContainer>
      <div className={styles.Login}>
        <div className={styles.AuthHeader}>
          <Text variant="heading 4" fontWeight="light">
            Welcome To UBELA!
          </Text>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <Form>
              <div className={styles.Form}>
                <div className={styles.InputWrapper}>
                  <Input
                    name="phoneNumber"
                    placeholder="Your Phone Number"
                    label="Enter Phone Number"
                    id="phoneNumber"
                    value={values.phoneNumber}
                    error={!!(touched.phoneNumber && errors.phoneNumber)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.phoneNumber && errors.phoneNumber && (
                    <Text variant="caption" color={colors.error}>
                      {errors.phoneNumber}
                    </Text>
                  )}
                </div>
                <div
                  style={{ marginBottom: "24px" }}
                  className={styles.InputWrapper}
                >
                  <Input
                    placeholder="Your Password"
                    label="Enter Password"
                    type={isPasswordVisible ? "text" : "password"}
                    inputAdornment={
                      isPasswordVisible ? <EyeClosed /> : <EyeOpen />
                    }
                    onClickAdornment={() =>
                      setIsPasswordVisible(!isPasswordVisible)
                    }
                    name="password"
                    id="password"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!(touched.password && errors.password)}
                  />
                  {touched.password && errors.password && (
                    <Text variant="caption" color={colors.error}>
                      {errors.password}
                    </Text>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    marginBottom: "50px",
                  }}
                >
                  <Link to="/app/forgot-password">
                    <Text
                      color={colors.primary}
                      fontWeight="light"
                      style={{ fontSize: "14px" }}
                    >
                      Forgot Password?
                    </Text>
                  </Link>
                </div>
                <Button
                  type="submit"
                  isLoading={isLoading}
                  onClick={handleSubmit}
                >
                  Sign in
                </Button>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "30px",
                    width: "100%",
                    background: colors.primary + "95",
                  }}
                >
                  <Text
                    color={colors.grey1}
                    style={{ fontSize: "22px", marginRight: "5px" }}
                  >
                    Don’t Have An Account?
                  </Text>
                  <button
                    type="button"
                    onClick={() => navigate("/app/sign-up")}
                  >
                    <Text
                      color={colors.white}
                      fontWeight="bold"
                      style={{ fontSize: "22px", fontFamily: "ComicSans" }}
                    >
                      Sign up
                    </Text>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </AuthContainer>
  );
}

export default Login;
